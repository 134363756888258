import axios from 'axios'
import { errorTip } from './tip'
import { checkStatus } from './checkStatus'


const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 50000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // if (store.getters.token) {
    //   config.headers['token'] = getToken()
    // }
    
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const { data } = response
    
    // 判断是否返回原生返回
    if (
      response.config &&
      response.config.headers &&
      response.config.headers.isReturnNativeResponse
    ) {
      return data
    }
    // 未拿到data，抛出异常
    if (!data) {
      // throw new Error('请求出错，请稍候重试')
      errorTip('请求出错，请稍候重试')
      return false
    }

    const { code, data: result, msg } = data

    // 成功
    const hasSuccess = data && (code === 0)
    if (hasSuccess) {
      return result
    }

    msg && errorTip(msg)
  },
  error => {
    const { response, code, message } = error || {}
    const msg = response?.data?.error?.message ?? ''
    const err = error?.toString?.() ?? ''
    let errMessage = ''
    try {
      if (code === 'ECONNABORTED' && message.indexOf('timeout') !== -1) {
        errMessage = '接口请求超时,请刷新页面重试!'
      }
      if (err?.includes('Network Error')) {
        errMessage = '网络异常，请检查您的网络连接是否正常!'
      }
      if (errMessage) {
        errorTip(errMessage)
        return Promise.reject(error)
      }
    } catch (error) {
      throw new Error(error)
    }
    checkStatus(error?.response?.status, msg)
    return Promise.reject(error)
  }
)

export default service
