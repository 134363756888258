import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import("../views/sso/index.vue"),
  },
  {
    path: "/apply",
    name: "apply",
    component: () =>
      import("../views/ApplicantPage.vue"),
  },
  {
    path: "/workshop",
    name: "workshop",
    component: () =>
      import("../views/WorkShop.vue"),
  },
  {
    path: "/process",
    name: "process",
    component: () =>
      import("../views/ProcessPage.vue"),
  },
  {
    path: "/detail",
    name: "detail",
    component: () =>
      import("../views/DetailPage.vue"),
  },
  {
    path: "/canteen",
    name: "canteen",
    component: () =>
      import("../views/SmartCanteen.vue"),
  },
  {
    path: '/',
    redirect: '/home'
  }
];

const router = new VueRouter({
  routes,
});

export default router;
