import http from '@/utils/http'






// 单点登录
export const getInfo = params => http.get(`/api/iscIntegrate/getUserInfo`,{ params })

// 获取常用功能和拓展功能
export const getFeatureList = data => http.post(`/api/apply/page?pageNum=${data.pageNum}&pageSize=${data.pageSize}`, {applyName: data.applyName})

// 获取流程列表
export const getProcessList = data => http.post(`/api/process/page`, data)


// 获取任务列表（待办，已处理，我发起的流程）
export const getTaskList = data => http.post(`/task/page`, data)


// 申请客餐券（提交申请保存表单）
export const saveApplicantForm = data =>  http.post(`/task/addOrUpd`, data)

// 获取审批人
export const getApproverList = (params) =>  http.get(`/api/processUser/getApprovers`, { params })

// 申请客餐券（待办审批-是否同意）
export const updateProcess = data =>  http.post(`/task/approve`, data )

