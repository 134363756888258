<template>
  <div class="container">
    <HeaderTop
      arrow-color="#fff"
      bg-color="#35C2A9"
    >
      <span
        class="header-title"
        style=""
      >智慧后勤</span>
    </HeaderTop>
    <div class="banner">
      <div class="banner-bg" />
      <div class="banner-img">
        <img
          src="../assets/banner.png"
          height="134px"
          alt=""
        >
      </div>
    </div>
    <div class="feature-wrap normal-feature-wrap">
      <div class="feature-title">
        常用功能
      </div>
      <div
        v-if="normalFeatures.length > 0"
        class="feature-list"
      >
        <div
          v-for="feature in normalFeatures"
          :key="feature.applyName"
          class="feature-item"
          @click="redirectPage(feature)"
        >
          <div class="feature-img">
            <img
              :src="feature.iconUrl"
              alt=""
            >
          </div>
          <div
            class="feature-name"
            :class="{ 'ellipsis': feature.applyName.length > 4 }"
          >
            {{ feature.applyName }}
          </div>
        </div>        
      </div>
      <div
        v-else
        class="no-data"
      >
        暂无数据
      </div>
    </div>
    <div class="feature-wrap mt30">
      <div class="feature-title">
        拓展功能
      </div>
      <div
        v-if="expandFeatures.length > 0"
        class="feature-list"
      >
        <div
          v-for="feature in expandFeatures"
          :key="feature.applyName"
          class="feature-item"
          @click="redirectPage(feature)"
        >
          <div class="feature-img">
            <img
              :src="feature.iconUrl"
              alt=""
            >
          </div>
          <div
            class="feature-name"
            :class="{ 'ellipsis': feature.applyName.length > 4 }"
          >
            {{ feature.applyName }}
          </div>
        </div>        
      </div>
      <div
        v-else
        class="no-data"
      >
        暂无数据
      </div>
    </div>
  </div>
</template>

<script>
import HeaderTop from '@/components/headerTop.vue';
import { getFeatureList } from '@/api/index.js'
import imagePath from '../assets/workshop.png'

export default {
  name: 'HomeView',
  components: {
    HeaderTop
  },
  data() {
    return {
      normalFeatures: [],
      expandFeatures: [
      {
          id: 100,
          applyName: '工作台',
          iconUrl: imagePath,
          redirectUrl: 'http://www.baidu.com',
          partitionType: 2,  
          displayStatus: 1
        },
      ]
    }
  },
  computed: {
    saphrId() {
      return '8909'
    }
  },
  mounted(){
    //this.getData()
    const data = this.$route.query.applyList
    
    this.normalFeatures = data.filter(item => {
      return item.partitionType === 1 && item.displayStatus === 1
    })
    const expandFeatures = data.filter(item => {
      return item.partitionType === 2 && item.displayStatus === 1
    })
    this.expandFeatures.push(...expandFeatures)
  },
  methods: {
    redirectPage(item) {
      const userId = localStorage.getItem('1012571.userId')
      if(item.applyName === '工作台') {
        this.$router.push('/workshop')
      } else if (item.redirectUrl === '/canteen') {
        this.$router.push('/canteen')
      }
    },
    async getData() {
      // if (!this.routeQuery.id) return
      // this.docData = (await getDocById(this.routeQuery.id)) || {}
      // this.$nextTick(() => {
      //   this.$refs.articleCtxRef?.setForm?.(this.docData)
      //   this.$refs.propertyFormRef?.setForm?.(this.docData)
      // })
      // partitionType 所属分区 1常用功能 2 拓展功能
      // displayStatus 显示状态 1显示，2隐藏

      const data = await getFeatureList({pageNum: 1,pageSize: 1000,applyName: '' }) || {}
      this.normalFeatures = data.records.filter(item => {
        return item.partitionType === 1 && item.displayStatus === 1
      })

      const expandFeatures = data.records.filter(item => {
        return item.partitionType === 2 && item.displayStatus === 1
      })

      this.expandFeatures.push(...expandFeatures)
    }
  }
};
</script>
<style scoped lang="scss">
  .header-title {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
  }
  .banner {
    position: relative;
    height: 184px;
    text-align: center;
    .banner-bg {
      height: 98px;
      background: linear-gradient(180deg, #35C2A9 5%, rgba(53, 194, 169, 0) 99%);
      
    }
    .banner-img {
      position: absolute;
      top: 20px;
      width: 100%;
    }
  }
  .normal-feature-wrap {
    .feature-list {
      .feature-img {
        background: linear-gradient(45deg, #2EA893 0%, rgba(9, 243, 203, 0.66) 100%);
      }
    }
  }
  .feature-wrap {
    color: #3D3D3D;
    .feature-title {
      padding: 0 20px;
      line-height: 20px;
      font-weight: 500;
      font-size: 14px;
    }
    .feature-list {
      display: flex;
      flex-wrap: wrap;
      .feature-item {
        display: flex;
        flex-direction: column;
        width: 25%;
        align-items: center;
        margin-top: 20px;
        padding: 0 4px;
      }
      .feature-img {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #EAF6F4;
        width: 60px;
        height: 60px;
        border-radius: 12px;
        img {
          width: 36px;
        }
      }
      .feature-name {
        line-height: 20px;
        margin-top: 10px;
        font-weight: 350;
        font-size: 14px;
      }
      .feature-name.ellipsis {
          width: 80px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
    }
  }
  .custom-image {
    text-align: center;
  }
  .custom-image .van-empty__image {
    width: 150px;
    height: 150px;
  }

</style>
