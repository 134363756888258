import Vue from 'vue';
import { Toast } from 'vant';

Vue.use(Toast);

// 错误消息
export const errorTip = msg => msg && Toast.fail(msg)

// 成功消息
export const successTip = msg => msg && Toast.success(msg);

// 消息
export const infoTip = msg => msg && Toast(msg);
