import router from './router'
// import { getToken } from '@/utils/auth' // get token from cookie
// import getPageTitle from '@/utils/get-page-title'

const whiteList = ['/login', '/canteen'] // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  if(to.path === from.path) {
    next(false)
  } else {
    // set page title
    //   document.title = getPageTitle(to.meta.title)

    // const hasToken = getToken()
    const userState = localStorage.getItem('1012571.userState')
    const userId = localStorage.getItem('1012571.userId')
    

    if (userState == 1 && userId) {
      next()
      return false
    } else {
      /* has no token */
      if (whiteList.indexOf(to.path) !== -1) {
        
        // in the free login whitelist, go directly
        next()
      } else {
        console.log('去单点登录');
        // other pages that do not have permission to access are redirected to the login page.
        router.push('/login')
      }
    }
  }
})

