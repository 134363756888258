import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import 'amfe-flexible'


// 时间日期转换器
var moment = require('dayjs')
Vue.prototype.$moment = moment

Vue.config.productionTip = false;
import "normalize.css/normalize.css";
import "@/styles/index.scss";
import 'vant/lib/button/style';
import 'vant/lib/tabs/style';

import './permission'

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
