

<template>
  <div
    class="header-top"
    :style="{backgroundColor: bgColor}"
  >
    <van-icon
      class="back"
      size="24"
      :color="arrowColor"
      name="arrow-left"
      @click="goBack"
    />
    <slot>
      {{ title }}
    </slot>
    <slot name="right" />
  </div>
</template>
  
  <script>
  import Vue from 'vue';

  import { Icon, NavBar, Sticky } from 'vant';
  Vue.use(Icon)
  Vue.use(NavBar)
  Vue.use(Sticky)
  export default {
    name: 'HeaderTop',
    props: {
      title: {
        type: String,
        default: ''
      },
      arrowColor: {
        type: String,
        default: '#0F1114'
      },
      bgColor: {
        type: String,
        default: '#fff'
      }
    },
    methods: {
        goBack() {
         this.$router.go(-1);
       }
    }
  };
  </script>
  

  <style lang="scss" scoped>
    .header-top {
      position: fixed;
      z-index: 999;
      top: 0;
      width: 100%;
      height: 44px;
      line-height: 44px;
      text-align: center;
      padding: 0 20px;
      border-bottom: 1px solid rgba(221, 221, 221, 0.3);
      .back {
        position: absolute;
        left: 20px;
        top: 50%;
        margin-top: -8px;
      }
    }

  </style>
  